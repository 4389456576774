<template>
  <div class="col m-6">
    <div class="container-fluid">
      <div class="row row-cols-1 row-cols-md-12 g-4">
        <div class="col mt-5">
          <div class="alert alert-danger mt-4" role="alert">網站服務調整 (2025/3/30起) <a href="https://www.facebook.com/just.bk.me/posts/967596972205081" target="_blank">見詳細公告</a></div>
        </div>
        <h4>
          <!-- <p class="mt-5">台灣虎航 24夏季第一波開賣各航點價格一覽表<small> (逐步更新，非完整)</small></p> -->
          <p class="mt-0">{{ main_title }}<small> (逐步更新，非完整)</small></p>
        </h4>
        <small class="mt-0">
          <!-- <h2><p>台灣虎航 24夏季第一波開賣各航點價格一覽表</p></h2> -->
          <ul>
            <li>若登入後仍查無資料，代表本站今日尚未進行資料更新，<span class="fs-4 text-danger">票價資料更新原則：不定期、出發日近的先</span></li>
            <li>溫馨提醒：如透過行動裝置進行操作，由於本站對於in-app瀏覽器（如Facebook 或 LINE 內建）支援程度不佳，如遇到資料無法呈現，可建議使用 Google Chrome、Safari 等系統原生或第三方瀏覽器</li>
            <!-- <li v-if="help_title !== ''">
              <span class="fs-6 text-danger">{{ help_title }}</span
              >，若沒有可以點擊一旁 <router-link to="/NewUser" class="btn btn-outline-primary"> 註冊 </router-link>
            </li> -->
            <li>若使用行動裝置，建議可切換成橫向檢視來得到較佳瀏覽體驗</li>
            <li>{{ help_title }}況</li>
            <li>僅供參考，由於資料本身具有時效性，建議要搭配參考資料取得時間 (UTC+8)</li>
            <li>{{ sub_title }}</li>
          </ul>
        </small>
        <template>
          <div class="ad-container">
            <Adsense data-ad-client="ca-pub-2965351057490665" data-ad-slot="5295990485" data-ad-format="auto" data-full-width-responsive="true"> </Adsense>
          </div>
        </template>
        <vue-good-table
          :line-numbers="false"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            perPage: 10,
            enabled: true,
            mode: 'pages',
          }"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
            initialSortBy: [
              // { field: 'departureDate', type: 'asc' },
              { field: 'fareAmount', type: 'asc' },
            ],
          }"
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table striped" />
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import Cookies from "js-cookie"
  import GlobalVar from "@/mixins/global_var.js"
  export default {
    name: "VueLowFare",
    // components: { Table },
    mounted() {
      // if (Cookies.get("login_token")) {
      const login_token = Cookies.get("login_token")
      this.checkLoginTokeVaild(login_token)

      let redirect_url = "/Login"

      if (this.$route.query.ref) {
        const ref = this.$route.query.ref
        const airline = ref.split("-")[0]
        const tag = ref.split("-")[1]
        redirect_url = `/Login?url=/LowFare?ref=${airline}-${tag}`
      }

      // this.flag = sessionStorage.getItem("ref")
      // if (!sessionStorage.getItem("is_email_confirm")) {
      // if (sessionStorage.getItem("is_email_confirm")) {
      this.columns.splice(4, 0, {
        label: "張數",
        width: "100px",
        field: "fare1Available",
        filterOptions: {
          styleClass: "class1", // class to be added to the parent th element
          enabled: true, // enable filter for this column
          placeholder: "", // placeholder for filter input
          filterValue: "", // initial populated value for this filter
          // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
          filterFn: this.columnFilterFn, //custom filter function that
          // trigger: "enter", //only trigger on enter not on keyup
        },
      })

      this.columns.splice(1, 0, {
        label: "航空業者",
        width: "100px",
        field: "airline",
        filterOptions: {
          styleClass: "class1", // class to be added to the parent th element
          enabled: true, // enable filter for this column
          placeholder: "", // placeholder for filter input
          filterValue: "", // initial populated value for this filter
          filterDropdownItems: ["MM", "SL", "VZ", "TR", "D7", "FD", "AK", "IT"], // dropdown (with selected values) instead of text input
          // filterFn: this.columnFilterFn, //custom filter function that
          // trigger: "enter", //only trigger on enter not on keyup
        },
      })
      // }
      // }
      // }
      let airline = null,
        tag = null

      this.main_title = "台灣虎航 25夏季班表第一波開賣"
      this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
      this.help_title = "所提供的票價資訊均是建立在「直飛，台灣出發的來回且大人人數是 1 位」的情況"

      if (sessionStorage) {
        sessionStorage.setItem("page", "LowFare")
        sessionStorage.setItem("ref", false)
        sessionStorage.removeItem("airline", airline)
        sessionStorage.removeItem("tag", tag)
      }

      if (Cookies) {
        Cookies.set("page", "LowFare", { secure: true })
        Cookies.set("ref", false, { secure: true })
        Cookies.remove("airline")
        Cookies.remove("tag")
      }

      if (this.$route.query.ref) {
        const ref = this.$route.query.ref
        airline = ref.split("-")[0]
        tag = ref.split("-")[1]

        // if (airline == "IT") {
        //   this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        // }

        // if (airline == "MM") {
        //   this.sub_title = "價格單位: simple peach，單程未稅 TWD"
        // }

        if (airline === "MM" && tag === "LOWNRT") {
          window.location.href = `/Lowfare`
          // this.main_title = "樂桃航空東京成田＝台北低價整理（MM623、MM625、MM627）"
          // this.sub_title = "價格單位: Minimum，單程未稅 TWD"
        }

        if (airline === "ALL" && tag === "LOWNRT") {
          window.location.href = `/Lowfare`
          // this.main_title = "樂桃、泰獅航、酷航東京成田＝台北低價整理（低於 TWD 4000 ）"
          // this.sub_title = "價格單位: Minimum/經濟艙/經濟艙，單程未稅 TWD"
        }

        if (airline === "ALL" && tag === "LOWKIX") {
          window.location.href = `/Lowfare`
          // this.main_title = "樂桃、泰越捷、全亞航台北＝大阪低價整理（低於 TWD 4000 ）"
          // this.sub_title = "價格單位: Minimum/Eco/經濟艙，單程未稅 TWD"
        }

        if (airline === "MM" && tag === "Sale") {
          window.location.href = `/Lowfare`
          // this.main_title = "樂桃航空 9/19 ~ 9/22 台灣出發優惠活動優惠價懶人包"
          // this.sub_title = "價格單位: simple peach，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "KHHOKJ") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 高雄＝岡山 新航線開賣活動懶人包"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "JPY1000") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 JPY 1000 懶人包（需要買日本來回）"
          // this.sub_title = "價格單位: tigerlight，單程未稅 JPY"
        }
        if (airline === "IT" && tag === "MOON") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 24夏季 9-10月 低價整理"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "W24P3") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 24冬季第三波開賣價格整理 (秋田/高知/普吉島/澳門)"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "TR" && tag === "TUESALE") {
          // window.location.href = `/Lowfare`
          this.main_title = "酷航週二酷閃活動"
          this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "JPWEEK") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航平日出發活動懶人包(日本)"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "KRWEEK") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航平日出發活動懶人包(韓國)"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "WEEKDAY") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航平日出發活動懶人包(泰國、澳門、越南)"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "FUKPUS") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航平日出發活動懶人包(日本福岡、韓國釜山)"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "AK" && tag === "KHHBKI") {
          window.location.href = `/Lowfare`
          // this.main_title = "AirAsia 高雄＝亞庇開航優惠活動"
          // this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }
        if (airline === "MM" && tag === "W24SALE") {
          window.location.href = `/Lowfare`
          // this.main_title = "樂桃國際線冬季優惠活動 (台灣出發，僅列各航點起始價)"
          // this.sub_title = "價格單位: Standard，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "2402CTS") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 24冬季 台北＝新千歲 2月增班 2599 整理"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "OD" && tag === "LOWSALE") {
          window.location.href = `/Lowfare`
          // this.main_title = "峇迪航空低價&促銷價整理"
          // this.sub_title = "票種及價格單位: 超級節省，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "OKJSALE") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 岡山 1899 整理"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "KMISALE") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 台北＝宮崎 開賣 1799 整理"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }

        if (airline === "D7" && tag === "KIX2299") {
          // window.location.href = `/Lowfare`
          // this.main_title = "亞洲航空 (AirAsia) 台北＝大阪 2799 以下整理"
          // this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }

        if (airline === "FD" && tag === "KHH3088") {
          window.location.href = `/Lowfare`
          // this.main_title = "泰國亞洲航空 (Thai AirAsia) 高雄＝東京成田 3388 以下整理"
          // this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }

        if (airline === "ALL" && tag === "FDAK") {
          window.location.href = `/Lowfare`
          // this.main_title = "亞洲航空集團 (FD、AK) 台北＝沖繩、高雄＝曼谷/東京成田/沙巴 低價整理"
          // this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }
        if (airline === "TR" && tag === "2024ITF") {
          window.location.href = `/Lowfare`
          // this.main_title = "酷航 ITF 線上旅展"
          // this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "P12Sale") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 P12 台灣冠軍優惠活動(日本線)"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "P12Sale2") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 P12 台灣冠軍優惠活動(澳門、韓國兩人同行一人免費)"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "1129Sale") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 11/29/ 一日限定優惠活動(限買來回且須兩人以上同行)"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
          // this.help_title = "所提供的票價資訊均是建立在「直飛，台灣出發的來回且大人人數是 2 位」的情況"
        }

        if (airline === "VZ" && tag === "OKA560") {
          window.location.href = `/Lowfare`
          // this.main_title = "泰越捷 25夏季 沖繩 560"
          // this.sub_title = "價格單位: eco，單程未稅 TWD"
          // this.help_title = "所提供的票價資訊均是建立在「直飛，台灣出發的來回且大人人數是 2 位」的情況"
        }

        if (airline === "IT" && tag === "S25Sale") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 25夏季班表第 4 波開賣"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
          // this.help_title = "所提供的票價資訊均是建立在「直飛，台灣出發的來回且大人人數是 2 位」的情況"
          // this.$set(this.columns[0], "hidden", true)
          // this.$set(this.columns[1], "hidden", true)
          // this.$set(this.columns[5], "hidden", true)
        }

        if (airline === "TR" && tag === "12Sale") {
          window.location.href = `/Lowfare`
          // this.main_title = "酷航12週年優惠活動"
          // this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "KR1499") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 24冬 韓國線 1499"
          // this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "W24KRLOW") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 24冬 韓國線低價票整理"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "W24JPLOW") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 24冬 日本線低價票整理"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "S25JPLOW") {
          // window.location.href = `/Lowfare`
          this.main_title = "台灣虎航 25夏 日本線低價票整理"
          this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "S25KRLOW") {
          // window.location.href = `/Lowfare`
          this.main_title = "台灣虎航 25夏 韓國線低價票整理"
          this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "BOXSALE") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 BoxingDay 優惠活動整理"
          // this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }

        if (airline === "TR" && tag === "S25LOW") {
          // window.location.href = `/Lowfare`
          this.main_title = "酷航 25夏 低價票整理"
          this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }

        if (airline === "MM" && tag === "W24JPLOW") {
          window.location.href = `/Lowfare`
          // this.main_title = "樂桃航空 24冬 日本線低價票整理"
          // this.sub_title = "價格單位: Minimum，單程未稅 TWD"
        }

        if (airline === "MM" && tag === "S25JPLOW") {
          // window.location.href = `/Lowfare`
          this.main_title = "樂桃航空 25夏 日本線低價票整理"
          this.sub_title = "價格單位: Minimum，單程未稅 TWD"
        }

        if (airline === "MM" && tag === "THANKS2025") {
          window.location.href = `/Lowfare`
          // this.main_title = "樂桃航空 感謝祭優惠活動（日本出發）"
          // this.sub_title = "價格單位: Minimum，單程未稅 JPY"
        }

        if (airline === "ALL" && tag === "KHHSAKURA") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航、泰國亞洲航空 高雄東京 櫻花花況範圍內低價整理"
          // this.sub_title = "價格單位: tigerlight/經濟艙，單程未稅 TWD"
        }

        if (airline === "ALL" && tag === "TPESAKURA") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航、酷航、樂桃航空、泰國獅子航空 台北東京 櫻花花況範圍內低價整理"
          // this.sub_title = "價格單位: tigerlight/經濟艙/Minimum/經濟艙，單程未稅 TWD"
        }

        if (airline === "MM" && tag === "S25SALE") {
          // window.location.href = `/Lowfare`
          this.main_title = "樂桃國際線 25夏季優惠活動 (台灣出發)"
          this.sub_title = "價格單位: Minimum，單程未稅 TWD"
        }
        if (airline === "TR" && tag === "BIGSALE") {
          window.location.href = `/Lowfare`
          // this.main_title = "酷航大促銷活動"
          // this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "VDSALE") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 白色情人節活動"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }

        if (airline === "SL" && tag === "S25NGOLOW") {
          // window.location.href = `/Lowfare`
          this.main_title = "泰國獅子航空 名古屋低價"
          this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "NOLOOKBACK") {
          // window.location.href = `/Lowfare`
          this.main_title = "台灣虎航 不走回頭路 - 鳥取(米子)、岡山"
          this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }

        if (airline === "ALL" && tag === "2026WBCJP") {
          // window.location.href = `/Lowfare`
          this.main_title = "泰國亞洲航空、酷航 2026WBC 東京機票價格整理 (2026/3/1 ~ 3/12)"
          this.sub_title = "價格單位: 經濟艙/經濟艙，單程未稅 TWD"
        }

        if (airline === "SL" && tag === "S25JPLOW") {
          // window.location.href = `/Lowfare`
          this.main_title = "泰國獅子航空 東京、沖繩、名古屋低價票整理"
          this.sub_title = "價格單位: 經濟艙/經濟艙，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "HSG2399") {
          // window.location.href = `/Lowfare`
          this.main_title = "台灣虎航 不走回頭路 - 佐賀 2399"
          this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }

        if (airline === "TR" && tag === "SCOOTPLUS") {
          // window.location.href = `/Lowfare`
          this.main_title = "酷航PLUS優惠活動"
          this.sub_title = "價格單位: 酷航PLUS，單程未稅 TWD"
        }

        if (airline === "D7" && tag === "KIX1890") {
          // window.location.href = `/Lowfare`
          this.main_title = "亞洲航空 台北大阪 低價整理"
          this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }

        if (airline === "D7" && tag === "S25JPLOW") {
          // window.location.href = `/Lowfare`
          this.main_title = "亞洲航空 台北大阪 低價票整理（暑假 2290）"
          this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "JP1799") {
          // window.location.href = `/Lowfare`
          this.main_title = "台灣虎航 日本 1799 價格整理"
          this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }

        if (airline === "IT" && tag === "NSWITCH2") {
          // window.location.href = `/Lowfare`
          this.main_title = "台灣虎航 SWICH2 日本發售日前後(6/1 ~ 6/10)價格整理"
          this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }

        if (airline === "FD" && tag === "KHHNRTLOW") {
          // window.location.href = `/Lowfare`
          this.main_title = "泰國亞洲航空 高雄東京成田 3388 整理"
          this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }

        if (airline === "TEST" && tag === "TT") {
          this.main_title = "測試用"
          this.sub_title = ""

          if (typeof sessionStorage !== "undefined") {
            alert("ok")
          } else {
            alert("not ok")
          }
        }

        // this.help_title = "如持有本站會員帳號，可於登入後查看該優惠價格所持有的張數"
        if (sessionStorage) {
          sessionStorage.setItem("ref", true)
          sessionStorage.setItem("airline", airline)
          sessionStorage.setItem("tag", tag)
        }
        if (Cookies) {
          Cookies.set("ref", true, { secure: true })
          Cookies.set("airline", airline, { secure: true })
          Cookies.set("tag", tag, { secure: true })
        }
      }

      if (!sessionStorage.getItem("is_email_confirm") && airline != null && tag != null) {
        this.$alert("從 2024/11/8 起一般會員（含驗證會員）於每月可查詢懶人包件數為 5 次，付費會員（或贊助會員）則不受限", "系統訊息", "error").then(() => {
          window.location.href = `${redirect_url}`
        })
      } else {
        this.initLowFare(airline, tag)
      }
    },
    mixins: [GlobalVar],
    data: function () {
      return {
        flag: null,
        main_title: "",
        sub_title: "",
        help_title: "",
        isLoading: false,
        columns: [
          {
            label: "前往官網",
            width: "150px",
            field: "goLink",
            html: true,
          },
          {
            label: "出發日",
            width: "150px",
            field: "departureDate",
            type: "date",
            dateInputFormat: "yyyy-MM-dd",
            dateOutputFormat: "yyyy-MM-dd",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "星期",
            field: "departureDateWeeKFormat",
            width: "130px",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "班次",
            field: "flightNo",
            width: "130px",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          // {
          //   label: "currency",
          //   field: "currency",
          // },
          {
            label: "價格",
            width: "130px",
            field: "fareAmount",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },

          {
            label: "出發地",
            width: "150px",
            field: "departureStation",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "目的地",
            width: "150px",
            field: "arrivalStation",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "資料時間",
            width: "150px",
            field: "fetchCreatedAt",
          },
        ],
        rows: [],
      }
    },
    methods: {
      initLowFare: async function (airline, tag) {
        // initLowFare(airline, tag) {
        const me = this
        if (airline == null && tag == null) {
          // axios.get(`${me.$apiUrl}lowfare`).then((response) => {
          //   me.rows = response.data
          // })
          // this.$set(this.columns[0], "hidden", true)
          // this.$set(this.columns[1], "hidden", true)
          // this.$set(this.columns[5], "hidden", true)
          window.location.href = `/`
        } else {
          let user_uuid = null

          if (sessionStorage) {
            if (sessionStorage.getItem("uuid")) {
              user_uuid = sessionStorage.getItem("uuid")
            }
          }

          if (Cookies) {
            if (Cookies.get("uuid")) {
              user_uuid = Cookies.get("uuid")
            }
          }

          const json_d = {
            airline: airline,
            tag: tag,
            uuid: user_uuid,
          }

          let usage = null
          let flag = false
          await axios({
            method: "POST",
            url: `${me.$apiUrl}querylowfare_usage2`,
            data: json_d,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
              "Content-Type": "application/json",
            },
          }).then(async function (response) {
            // usage = response.data
            if (response.data.length === 0) {
              flag = true
            } else {
              usage = response.data[0]
              // console.log("usage", response.data)

              if (sessionStorage.getItem("role_type") === "Sponsored") {
                flag = true
              } else {
                if (usage.count < 5 && usage.count >= 0) {
                  flag = true
                } else {
                  flag = false
                }
              }
            }

            if (flag) {
              await axios({
                method: "POST",
                url: `${me.$apiUrl}lowfare`,
                data: json_d,
                headers: {
                  Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                  "Content-Type": "application/json",
                },
              }).then(function (response) {
                me.rows = response.data
              })
            } else {
              me.$alert("超過每月可查詢件數額度", "系統訊息", "error")
            }
          })
        }
      },
      columnFilterFn: function (data, filterString) {
        const x = parseInt(filterString)
        return data == x
        // return x
        // return data >= x - 5 && data <= x + 5
      },
    },
  }
</script>

<style></style>
